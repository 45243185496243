import './Partners.css';

export default function Partners() {
    return (
      <div className="partners-back bg-gray-300 py-16">
        <div className="flex flex-col mx-auto max-w-7xl px-6 lg:px-8">
          <h2 className="our-partners text-4xl font-bold inline-block mx-auto text-center text-gray-900 mb-10">
            Our Partners
          </h2>
          <div className="flex flex-wrap justify-around items-center sm:flex-col md:flex-row lg:flex-row ">
            <div>
            <img
              src="./images/kurvalf.png"
              alt="Kurvalf"
              width={200}
              className='zoom-pic'
            />
            </div>
            <div>
            <img
              className="object-contain h-24 w-48 mb-8 zoom-pic"
              src="./images/turkmengaz.png"
              alt="TurkmenGaz"
              
            />
            </div>
            <div>
            <img
              className="object-contain h-24 w-48 zoom-pic"
              src="./images/union.png"
              alt="Vastas"

            />
            </div>
            <div>
            <img
              className="object-contain h-24 w-48 zoom-pic"
              src="./images/vastas.png"
              alt="Vastas"

            />
            </div>
          </div>
        </div>
      </div>
    )
  }
  