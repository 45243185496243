// App.jsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Products from './pages/products';
import AboutUs from './pages/about-us.jsx';
import Contact from './pages/contact.jsx';
import Nav from './components/nav/Nav.jsx';
import About from './components/about/About.jsx';
import Footer from './components/footer/Footer.jsx';

const App = () => {
  return (
    <Router>
      <div>
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <About/>
        <Footer/>
      </div>
    </Router>
  );
};

export default App;
