import React, { useState } from 'react';
import productsData from './products.json'; // Adjust path if needed
import "./productscontainer.css";

const ProductContainer = () => {
  const [selectedProduct, setSelectedProduct] = useState(productsData[0]);

  return (
    <div className='bg-gray-200'>
      <div className="relative isolate overflow-hidden bg-gray-900 py-10 sm:py-16">
        <img
          src="./images/man-comp.jpeg"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover object-top-left brightness-50 opacity-75"
        />
        <div className="relative text-center mb-4">
          <h1 className="products-h1 text-4xl text-white font-bold relative z-10">Products</h1>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row container mx-auto py-20">
        {/* Sidebar */}
        <div className="product-container-sidebar text-white w-full lg:w-1/4 p-4">
          <h2 className="text-xl font-bold mb-4">Product Category</h2>
          <ul>
            {productsData.map((product, index) => (
              <li
                key={index}
                className={`cursor-pointer p-2 ${selectedProduct.name === product.name ? 'sidebar-active' : ''}`}
                onClick={() => setSelectedProduct(product)}
              >
                {product.name}
              </li>
            ))}
          </ul>
        </div>

        {/* Product Display */}
        <div className="flex-1 p-8">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
              <div>
                <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Technical Specifications</h2>
                <p className="mt-4 text-gray-500">{selectedProduct.description}</p>

                <dl className="mt-8 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2 lg:gap-x-8">
                  <div className="border-t border-gray-200 pt-4">
                    <dt className="font-medium text-gray-900">Outer Diameter</dt>
                    <dd className="mt-2 text-sm text-gray-500">{selectedProduct.outerDiameter}</dd>
                  </div>
                  <div className="border-t border-gray-200 pt-4">
                    <dt className="font-medium text-gray-900">Wall Thickness</dt>
                    <dd className="mt-2 text-sm text-gray-500">{selectedProduct.wallThickness}</dd>
                  </div>
                  <div className="border-t border-gray-200 pt-4">
                    <dt className="font-medium text-gray-900">Length</dt>
                    <dd className="mt-2 text-sm text-gray-500">{selectedProduct.length}</dd>
                  </div>
                  <div className="border-t border-gray-200 pt-4">
                    <dt className="font-medium text-gray-900">End</dt>
                    <dd className="mt-2 text-sm text-gray-500">{selectedProduct.end}</dd>
                  </div>
                  <div className="border-t border-gray-200 pt-4">
                    <dt className="font-medium text-gray-900">Application</dt>
                    <dd className="mt-2 text-sm text-gray-500">{selectedProduct.application}</dd>
                  </div>
                </dl>
              </div>
              <img src={selectedProduct.imageUrl} alt={selectedProduct.name} className="w-full h-auto rounded-lg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductContainer;