import React from "react";
import "./header.css";

const Header = () => {
  return (
    <div className="header-merkez px-6 pt-10 py-10 lg:px-8 ">
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      ></div>
      <div className="lg:flex justify-center items-center sm:flex-col-reverse md:flex-row gap-5 mx-auto max-w-6xl sm:py-28 lg:py-32">
        <div className="text-center esas-h1 flex-1">
          <h1 className="font-bold lg:text-left tracking-tight text-slate-50 text-3xl md:text-4xl lg:text-5xl tracking-normal">
            Transform your business with Industrial solutions
          </h1>
          <p className="mt-6 text-lg lg:text-left leading-8 text-gray-500">
            Elevate your business to new heights with our premium azote and
            industrial products, engineered for excellence
          </p>

          <div className="mt-10 flex lg:justify-left gap-x-6">
            <a href="#about" className="button2 font-semibold" role="button">
              <span>Explore &nbsp;</span>
              <span>&rarr;</span>
            </a>
          </div>
        </div>
        <div className="flex-1 mt-5">
          <img src="./images/man-helmet.png" className="esas-image opacity-80" alt="man" />
        </div>
      </div>
      <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      ></div>
    </div>
  );
};

export default Header;
