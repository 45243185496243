import React, { useState } from 'react';
import "./ContactUs.css"
const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log('Form submitted:', formData);

    // Clear the form after submission (optional)
    setFormData({
      name: '',
      email: '',
      message: ''
    });
  };

  return (
    <section className="body-font relative" style={{ backgroundColor: '#081C15', color: '#C7E5B4' }}>
      <div className="relative isolate overflow-hidden bg-gray-900 py-10 sm:py-16">
      <img
        src="./images/hero.jpg"
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover object-center brightness-50  opacity-85"
      />
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#66ff66] to-[#006400] opacity-20"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#32cd32] to-[#006400] opacity-20"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="relative text-center mb-4">
        <h1 className="text-4xl text-white font-bold relative z-10">Contact Us</h1>
        
      </div>
         </div>
      <div className="container mx-auto px-5 py-24">
        <div className="mb-12 flex w-full flex-col text-center">
          <p className="mx-auto text-base leading-relaxed lg:w-2/3">
            Feel free to reach out to us! Whether you have a question, feedback, or a collaboration proposal, we'd love to hear from you.
          </p>
        </div>

        <div className="mx-auto md:w-2/3 lg:w-1/2">
        <form action="https://fabform.io/f/{form-id}" method="post" onSubmit={handleSubmit}>
  <div className="-m-2 flex flex-wrap">
    {/* Name Input */}
    <div className="w-1/2 p-2">
      <div className="relative">
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="peer w-full rounded border border-gray-700 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-8 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-green-500 focus:bg-gray-700 focus:ring-2 focus:ring-green-500"
          placeholder="Name"
        />
        <label
          htmlFor="name"
          className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-green-500 transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:bg-gray-900 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-green-500"
        >
          Name
        </label>
      </div>
    </div>

    {/* Email Input */}
    <div className="w-1/2 p-2">
      <div className="relative">
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="peer w-full rounded border border-gray-700 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-8 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-green-500 focus:bg-gray-700 focus:ring-2 focus:ring-green-500"
          placeholder="Email"
        />
        <label
          htmlFor="email"
          className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-green-500 transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:bg-gray-900 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-green-500"
        >
          Email
        </label>
      </div>
    </div>

    {/* Message Textarea */}
    <div className="mt-4 w-full p-2">
      <div className="relative">
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          className="peer h-32 w-full resize-none rounded border border-gray-700 bg-gray-800 bg-opacity-40 py-1 px-3 text-base leading-6 text-gray-100 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-green-500 focus:bg-gray-700 focus:ring-2 focus:ring-green-500"
          placeholder="Message"
        />
        <label
          htmlFor="message"
          className="absolute left-3 -top-6 bg-transparent text-sm leading-7 text-green-500 transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-2 peer-placeholder-shown:bg-gray-900 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:left-3 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-green-500"
        >
          Message
        </label>
      </div>
    </div>

    {/* Submit Button */}
    <div className="w-full p-2">
      <button
        type="submit"
        className="mx-auto flex rounded contact-button px-5 py-2.5"
      >
        Send
      </button>
    </div>
  </div>
</form>


<div className="mt-8 w-full border-t border-gray-800 p-2 pt-8 text-center">
  <a href="tel:+971526267933" className="font-medium text-blue-600 hover:underline">
    +971 52 626 7933
  </a>
  <br />
  <a href="mailto:info@brothersalliancetrading.com" className="font-medium text-blue-600 hover:underline">
    info@brothersalliancetrading.com
  </a>
  <p className="my-5 leading-normal"></p>
  <p className="my-5 leading-normal">
    No. A4, Standard Office 10055, <br />
    Al Hamra Industrial Zone-FZ, Ras Al Khaimah
  </p>

  {/* Google Maps Embed */}
  <div className="w-full h-64 sm:h-96 overflow-hidden rounded-lg mt-4">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12537.896924239572!2d28.837257200000003!3d40.9954135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa3df244ef7c9%3A0x3b02170bb8c0405e!2sIstwest!5e0!3m2!1sen!2str!4v1696794023301!5m2!1sen!2str"
      width="100%"
      height="100%"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
</div>

        </div>
      </div>
    </section>
  );
};

export default ContactUs;
