import React from 'react'
import {  Esas,Footer, Stats, Partners, Solutions, Header,About} from '../components'; 
import FeaturesSection from '../components/features/Features';

const Home = () => {
  return (
    <div>
        <Header/>
        <Esas/>
        <Stats/>
        <Solutions/>
        <Partners/>
        
        <FeaturesSection/>
       
    </div>
  )
}

export default Home
