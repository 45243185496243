import React from 'react';
import './features.css';
//import { FaChartPie, FaThumbsUp, FaBoxOpen, FaChartLine, FaUserPlus, FaHeadset } from 'react-icons/fa';
import { FaIndustry, FaDollarSign, FaShieldAlt, FaHandshake, FaTruck, FaTools } from 'react-icons/fa';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="flex flex-col items-center text-center p-6 border rounded-lg shadow-md bg-white hover:scale-105 ease-in duration-200 max-w-xs sm:max-w-none mx-auto sm:mx-0">
    <div className="text-4xl features-icon-color mb-4">
      <Icon />
    </div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const FeaturesSection = () => {
 /* const features = [
    {
      icon: FaChartPie,
      title: 'Rich Experience',
      description: '20+ years experience in high quality steel pipe, petrochemical, chemical, fertilizer manufacturing, stocking and exporting',
    },
    {
      icon: FaThumbsUp,
      title: 'Competitive Price',
      description: 'Strong cooperation network with raw material mills contribute to a competitive price than market',
    },
    {
      icon: FaBoxOpen,
      title: 'Outstanding Quality',
      description: 'Perfect quality control process and strict & professional quality control team provide 100% quality assurance',
    },
    {
      icon: FaChartLine,
      title: 'Sales',
      description: 'Experience the difference of our personalized sales approach, where you are always our top priority.',
    },
    {
      icon: FaUserPlus,
      title: 'Onboarding',
      description: 'Our onboarding process is designed to be simple and intuitive, so you can start using our platform right away.',
    },
    {
      icon: FaHeadset,
      title: 'Support',
      description: 'Our commitment to exceptional support ensures that you receive the assistance you need, whenever you need it.',
    },
  ];
*/

const features = [
  {
      icon: FaIndustry,  // Changed to a more industry-representative icon
      title: 'Rich Experience',
      description: '20+ years of expertise in high-quality steel pipe, petrochemical, chemical, and fertilizer manufacturing, stocking, and exporting.',
  },
  {
      icon: FaDollarSign,  // Updated to represent competitive pricing
      title: 'Competitive Price',
      description: 'Our strong network with raw material mills allows us to offer competitive prices in the market.',
  },
  {
      icon: FaShieldAlt,  // Represents quality and assurance
      title: 'Outstanding Quality',
      description: 'A strict quality control process and professional team provide 100% quality assurance.',
  },
  {
      icon: FaHandshake,  // Represents personalized customer service
      title: 'Client-Centric Sales',
      description: 'Experience the difference of our personalized sales approach, where you are always our top priority.',
  },
  {
      icon: FaTruck,  // Represents shipping and logistics for industrial products
      title: 'Efficient Logistics',
      description: 'Our efficient logistics process ensures timely delivery of products, minimizing downtime for your operations.',
  },
  {
      icon: FaTools,  // Represents technical support
      title: 'Reliable Support',
      description: 'Our commitment to exceptional support ensures that you receive assistance whenever you need it, tailored to industrial requirements.',
  },
];
  return (
    <div className="py-24 bg-gray-100">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-4xl font-bold mb-6">Why Choose Us</h2>
        <p className="text-gray-600 mb-12">
          At every step of our process, we prioritize the user, ensuring that our products and services are designed with their needs in mind.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
