import React from "react";
import "./footer.css";
import { FaLinkedin, FaWhatsapp} from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import { IoIosCall } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div className="mx-auto max-w-screen-xl px-4 pb-8 pt-16 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-md">
            <strong className="block text-center text-xl font-bold text-gray-300 sm:text-3xl">
              The latest news, product features, and resources, sent to your
              inbox weekly.
            </strong>

            <form className="mt-6">
              <div className="relative max-w-lg">
                <label className="sr-only" htmlFor="email">
                  {" "}
                  Email{" "}
                </label>

                <input
                  className="w-full rounded-full border-gray-200 bg-gray-100 p-4 pe-32 text-sm font-medium"
                  id="email"
                  type="email"
                  placeholder="example@.com"
                />

                <button className="pale-green absolute end-1 top-1/2 -translate-y-1/2 rounded-full px-5 py-3 text-sm font-medium text-white transition hover:bg-green-700">
                  Subscribe
                </button>
              </div>
            </form>
          </div>

          <div className="mt-16 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-32">
            <div className="mx-auto max-w-sm lg:max-w-none">
              <img
                className="h-14  w-auto"
                src="./logo512.png"
                alt="Brothers Alliance logo"
              />
              <p className="mt-4 text-center text-gray-500 lg:text-left lg:text-lg">
                Brothers Alliance is a reliable solution partner provides fast
                and timely product supply to its customers with its modern
                production infrastructure.
              </p>

              <div className="mt-6 flex justify-center gap-4 lg:justify-start">
                <a
                  className="text-gray-700 transition hover:text-gray-300"
                  href="https://wa.me/905058032530?text=I'm%20interested%20business%20with%20your%20company"
                >
                  <FaWhatsapp className="text-xl" />
                </a>

                <a
                  className="text-gray-700 transition hover:text-gray-300"
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin className="text-xl" />
                </a>
              </div>
            </div>

            <div className="grid grid-cols-1 text-center sm:grid-cols-2 gap-y-8 lg:grid-cols-2 lg:text-left">
  <div>
    <a className="font-medium text-gray-500">Quick Links</a>
    <ul className="mt-4 space-y-1">
      <li>
        <a
          className="text-gray-500 transition hover:text-gray-700/75"
          href="/"
        >
          Home
        </a>
      </li>
      <li>
        <a
          className="text-gray-500 transition hover:text-gray-700/75"
          href="/about-us"
        >
          About
        </a>
      </li>
      <li>
        <a
          className="text-gray-500 transition hover:text-gray-700/75"
          href="/products"
        >
          Products
        </a>
      </li>
    </ul>
  </div>

  <div>
    <a className="font-medium text-gray-500">Contact</a>
    <ul className="mt-6 space-y-3">
      <li className="flex items-center justify-center sm:justify-start lg:justify-start">
        <CiMail className="text-gray-500 text-lg sm:text-xl" />
        <a
          className="text-gray-500 transition ml-2"
          href="mailto:info@brothersalliancetrading.com"
        >
          info@brothersalliancetrading.com
        </a>
      </li>
      <li className="flex items-center justify-center sm:justify-start lg:justify-start">
        <IoIosCall className="text-gray-500 text-lg sm:text-xl" />
        <a
          className="text-gray-500 transition ml-2"
          href="tel:+971526267933"
        >
          +971 52 626 7933
        </a>
      </li>
      <li className="flex items-center justify-center sm:justify-start lg:justify-start">
        <FaLocationDot  className="text-gray-500 text-lg sm:text-xl" />
        <a className="text-gray-500 transition ml-2" href="#">
          No. A4, Standard Office 10055, <br />
          Al Hamra Industrial Zone-FZ, Ras Al Khaimah
        </a>
      </li>
    </ul>
  </div>
</div>

          </div>

          <div className="mt-16 border-t border-gray-100 pt-8">
            <p className="text-center text-xs/relaxed text-gray-500">
              © BrothersAlliance 2024. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
