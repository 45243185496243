import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { AiOutlineHome, AiFillProduct } from 'react-icons/ai';
import { IoIosInformationCircleOutline, IoIosCall } from "react-icons/io";
import { MdEmail, MdPhone } from "react-icons/md";
import "./nav.css";

const navigation = [
  { name: "Home", href: "/" },
  { name: "Products", href: "/products" },
  { name: "About us", href: "/about-us" },
];

export default function Nav() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="relative">
      {/* Contact Info at the Top */}
      <div className="contact-info-nav p-3 px-4 lg:px-24 hidden lg:flex lg:justify-end">
        <div className="flex flex-col lg:flex-row lg:justify-end lg:items-center lg:gap-6">
          <div className="flex items-center gap-1 lg:gap-2 mb-2 lg:mb-0">
            <MdPhone className="text-base" />
            <span className="text-xs">+971 52 626 7933</span>
          </div>
          <div className="flex items-center gap-1 lg:gap-2">
            <MdEmail className="text-base" />
            <span className="text-xs">info@brothersalliancetrading.com</span>
          </div>
        </div>
      </div>

      {/* Main Navigation */}
      <header className="navigation-ust inset-x-0 top-0 z-50 shadow-lg">
        <nav
          className="flex items-center justify-between p-2 sm:px-6 lg:px-24"
          aria-label="Global"
        >
          <div className="flex items-center lg:flex-1">
            <a href="/" className="p-1.5">
              <span className="sr-only">BrothersAlliance</span>
              <img className="h-16 w-auto" src="./logo512.png" alt="Brothers Alliance logo" />
            </a>
          </div>

          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => {
              let IconComponent;
              if (item.name === 'Home') IconComponent = AiOutlineHome;
              else if (item.name === 'Products') IconComponent = AiFillProduct;
              else if (item.name === 'About us') IconComponent = IoIosInformationCircleOutline;

              return (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-lg font-semibold leading-6 text-gray-400 hover:text-white flex items-center gap-x-2"
                >
                  {IconComponent && <IconComponent />}
                  {item.name}
                </a>
              );
            })}
          </div>

          <div className="hidden lg:flex lg:flex-1 lg:justify-end gap-2 items-center">
            <a
              href="/contact"
              className="nav-ust-button flex items-center gap-2 rounded-md px-3 py-2.5 text-base font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-0"
            >
              <IoIosCall className="ios-call"/>
              Contact
            </a>
          </div>
        </nav>

        <Dialog open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} className="lg:hidden">
          <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">BrothersAlliance</span>
                <img className="h-14 w-auto" src="./logo512.png" alt="Brothers Alliance logo" />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="mt-10 flex justify-center">
                  <a
                    href="#about"
                    className="nav-ust-button rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline"
                  >
                    Explore
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </header>
    </div>
  );
}
