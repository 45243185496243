import React from 'react';
import ContactUs from '../containers/ContactUs/ContactUs';

const Contact = () => {
  return (
    <div>
      <ContactUs/>
    </div>
  )
}

export default Contact
