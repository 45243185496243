import React from 'react'
import AboutUsContainer from '../containers/AboutUs/AboutUsContainer';

const AboutUs = () => {
  return (
    <div>
      <AboutUsContainer/>
    </div>
  )
}

export default AboutUs
