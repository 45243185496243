import React, { useEffect, useState, useRef } from "react";
import "./stats.css";
const stats = [
  { id: 1, name: "Transactions every month", value: 10000 },
  { id: 2, name: "Assets under holding", value: 30000000 },
  { id: 3, name: "New users annually", value: 46000 },
];

const formatNumber = (num) => {
  if (num >= 1000000) {
    return Math.floor(num / 1000000); // Remove decimals and show only the number before "million"
  }
  if (num >= 1000) {
    return Math.floor(num / 1000); // Remove decimals and show only the number before "thousand"
  }
  return num;
};

const formatSuffix = (num) => {
  if (num >= 1000000) return " million";
  if (num >= 1000) return " thousand";
  return "";
};

export default function Stats() {
  const [counts, setCounts] = useState(stats.map(() => 0));
  const [hasAnimated, setHasAnimated] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const countToValue = (value, index) => {
      let start = 0;
      const duration = 2000;
      const increment = value / (duration / 100);
      const interval = setInterval(() => {
        start += increment;
        if (start >= value) {
          clearInterval(interval);
          setCounts((prevCounts) => {
            const newCounts = [...prevCounts];
            newCounts[index] = value;
            return newCounts;
          });
        } else {
          setCounts((prevCounts) => {
            const newCounts = [...prevCounts];
            newCounts[index] = Math.ceil(start);
            return newCounts;
          });
        }
      }, 100);
    };

    const startCounting = () => {
      if (!hasAnimated) {
        stats.forEach((stat, index) => countToValue(stat.value, index));
        setHasAnimated(true);
      }
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            startCounting();
          }
        });
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [hasAnimated]);

  return (
    <div ref={sectionRef} className="bg-gray-300 py-20 sm:py-32">
      <div className="mx-auto flex flex-col max-w-7xl px-6 lg:px-8">
        <h2 className="business-highlights text-4xl font-bold inline-block mx-auto text-center text-gray-900 mb-20">
          Business Highlights
        </h2>
        <dl className="grid grid-cols-1 gap-y-12 text-center lg:grid-cols-3">
          {stats.map((stat, index) => (
            <div
              key={stat.id}
              className={`mx-auto flex max-w-xs flex-col gap-y-4 relative ${
                index < 3 ? "border-2 p-10 border-gray-700 px-10" : ""
              }`} // Add left and right borders with spacing (padding-x)
            >
              <dt className="text-base leading-7 text-gray-600">{stat.name}</dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl transition-all duration-500">
                {formatNumber(counts[index])}
                <span className="text-2xl">{formatSuffix(counts[index])}</span> {/* Add thousand/million in smaller font */}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
