import React from 'react';
import ProductsContainer from '../containers/Products/ProductsContainer';

const Products = () => {
  return (
    <>
      <ProductsContainer/>
    </>
  )
}

export default Products
